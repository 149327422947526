import { Button, Icon, Stack, Text } from '@gr4vy/poutine-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { isBoolean } from 'lodash'
import { useState } from 'react'
import { AddCertificateDrawer } from 'connections/components/AddCertificateDrawer/AddCertificateDrawer'
import CertificatesTable from 'connections/components/CertificatesTable'
import {
  ApplePayCertificate,
  listApplePayCertificates,
  removeApplePayCertificate,
} from 'connections/services/applePayCertificates'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import { APPLE_PAY_CERTIFICATES } from 'shared/constants'
import { useCursor, useFilters } from 'shared/hooks'
import {
  useResourcePermission,
  Resource,
  AccessLevel,
} from 'shared/permissions'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'
import { ApplePayLayout } from './ApplePayLayout'

export interface Filters {
  limit?: number
}

export const ApplePayCertificates = ({ title }: { title: string }) => {
  const [addingCertificate, setAddingCertificate] = useState<
    ApplePayCertificate | boolean
  >(false)
  const [removingCertificate, setRemovingCertificate] =
    useState<ApplePayCertificate | null>(null)
  const queryClient = useQueryClient()
  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters()

  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  const page = useQuery({
    queryKey: [APPLE_PAY_CERTIFICATES, cursor, filters],
    queryFn: () => listApplePayCertificates({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const { mutate: remove, isPending: removing } = useMutation({
    mutationFn: removeApplePayCertificate,
    onSuccess: handleSuccessResponse(
      `${removingCertificate?.displayName} certificate successfully removed.`,
      () => {
        queryClient.invalidateQueries({ queryKey: [APPLE_PAY_CERTIFICATES] })
        setRemovingCertificate(null)
      }
    ),
    onError: () => {
      showErrorMessage('Unable to remove the certificate. Try again.')
    },
  })

  const onFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <ApplePayLayout
      title={title}
      actions={
        <Button
          disabled={!hasWritePermission}
          variant="primary"
          onClick={() => setAddingCertificate(true)}
        >
          <Icon name="add-plus" />
          Add iOS certificate
        </Button>
      }
    >
      <CertificatesTable
        page={page}
        pagination={{
          filters,
          onFilter,
        }}
        onResume={setAddingCertificate}
        onRemove={setRemovingCertificate}
      />
      <AddCertificateDrawer
        open={!!addingCertificate}
        certificate={isBoolean(addingCertificate) ? null : addingCertificate}
        onClose={() => setAddingCertificate(false)}
      />
      {removingCertificate && (
        <ModalRemove
          title="Are you sure you want to remove this certificate?"
          okText="Yes, remove"
          loading={removing}
          loadingText="Removing..."
          onCancel={() => setRemovingCertificate(null)}
          onOk={() => {
            remove(removingCertificate.id)
          }}
        >
          {removingCertificate.status === 'active' ? (
            <Stack gap={16}>
              <Text>
                By performing this action the certificate will no longer be
                available and we won&apos;t be able to process any Apple Pay
                transactions that have been created with this certificate. It
                may take up to 10 minutes for a certificate to become fully
                unavailable.
              </Text>
              <Text>
                To rotate your certificates it is recommended to register a new
                certificate first, ensuring that no Apple Pay requests are being
                processed with this certificate, before removing it.
              </Text>
            </Stack>
          ) : (
            'By performing this action the certificate will no longer be available.'
          )}
        </ModalRemove>
      )}
    </ApplePayLayout>
  )
}
