import { Filters } from 'shared/hooks'

export interface AdvancedSearchFilters extends Filters {
  id?: string
  externalIdentifier?: string
  paymentServiceTransactionId?: string
  paymentMethodLabel?: string
  metadata?: string
  email?: string
}

export enum SearchOptionsKey {
  id = 'id',
  reconciliation_id = 'reconciliation_id',
  connector_id = 'payment_service_transaction_id',
  label = 'payment_method_label',
  last4 = 'payment_method_label',
  gift_card_last4 = 'gift_card_last4',
  merchant_reference = 'external_identifier',
  VisitorID = 'metadata',
  email = 'buyer_email_address',
  fingerprint = 'payment_method_fingerprint',
  bin = 'payment_method_bin',
  payment_source = 'payment_source',
  is_subsequent_payment = 'is_subsequent_payment',
  merchant_initiated = 'merchant_initiated',
}

export type SearchOptionsIndex = keyof typeof SearchOptionsKey

export const [
  id,
  reconciliationId,
  connectorId,
  label,
  last4,
  giftCardLast4,
  merchantReference,
  metadata,
  email,
  fingerprint,
  paymentMethodBin,
  paymentSource,
  isSubsequentPayment,
  merchantInitiated,
] = Object.keys(SearchOptionsKey) as SearchOptionsIndex[]

export type SearchOption = {
  [key in SearchOptionsKey as SearchOptionsIndex]: {
    title: string
    description: string
  }
}

export const advancedSearchOptions = {
  [id]: {
    title: 'id=e06dd3e4-1711-4bfa-9438-10a8accd3cee',
    description: 'The Gr4vy transaction ID.',
  },
  [label]: {
    title: 'label=email@example.com',
    description:
      'The label of a payment method, like the email of a PayPal account.',
  },
  [last4]: {
    title: 'last4=1234',
    description: 'The last 4 digits of a card.',
  },
  [giftCardLast4]: {
    title: 'gift_card_last4=4321',
    description: 'The last 4 digits of a gift card.',
  },
  [merchantReference]: {
    title: 'merchant_reference=order-1234',
    description:
      'Your own merchant transaction ID, also known as the external identifier.',
  },
  [email]: {
    title: 'email=email@example.com',
    description:
      'The email address of the buyer at the time of the transaction.',
  },
  [reconciliationId]: {
    title: 'reconciliation_id=Xk3R3WFWG742sN5myAiVA',
    description:
      'The base62 encoded transaction ID. This represents a shorter version of this transactions id which is sent to payment services',
  },
  [connectorId]: {
    title: 'connector_id=payment-2345',
    description:
      'The transaction ID as returned by the payment service, also known as the payment service transaction ID.',
  },
  [metadata]: {
    title: 'VisitorID=12345678',
    description: 'Any piece of metadata associated to a transaction.',
  },
  [fingerprint]: {
    title: 'fingerprint=20eb353620155d2b5fc864cc46a73ea77cb92...',
    description: 'The fingerprint of the card number used.',
  },
  [paymentMethodBin]: {
    title: 'bin=123456',
    description: 'The first six digits of a card.',
  },
  [paymentSource]: {
    title: 'payment_source=recurring',
    description:
      'The recurring context in which the transaction was created. Available options: recurring, ecommerce, moto, installment and card_on_file.',
  },
  [isSubsequentPayment]: {
    title: 'is_subsequent_payment=true',
    description:
      'Whether the transaction was an initial or subsequent payment in a recurring series.',
  },
  [merchantInitiated]: {
    title: 'merchant_initiated=true',
    description: 'Whether the transaction was merchant or customer initiated.',
  },
} as SearchOption

export const emptyAdvancedSearchFilters = Object.fromEntries(
  Object.values(SearchOptionsKey).map((value) => [value, undefined])
) as { [K in SearchOptionsKey]: undefined }
