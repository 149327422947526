import {
  AdvancedSearchFilters,
  emptyAdvancedSearchFilters,
  id,
  reconciliationId,
  connectorId,
  label,
  last4,
  email,
  merchantReference,
  fingerprint,
  SearchOptionsIndex,
  SearchOptionsKey,
  giftCardLast4,
  paymentMethodBin,
  paymentSource,
  isSubsequentPayment,
  merchantInitiated,
} from 'transactions/constants'

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const getFiltersBySearch = (search?: string) => {
  const newFilters = search
    ?.split(/\b\s/g)
    .filter(Boolean)
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=')
      acc

      if (key === reconciliationId) {
        return { ...acc, [SearchOptionsKey[reconciliationId]]: value?.trim() }
      }

      if (key === connectorId) {
        return { ...acc, [SearchOptionsKey[connectorId]]: value?.trim() }
      }

      if (key === merchantReference) {
        return { ...acc, [SearchOptionsKey[merchantReference]]: value?.trim() }
      }

      if (key === email) {
        return { ...acc, [SearchOptionsKey[email]]: value?.trim() }
      }

      if (key === fingerprint) {
        return { ...acc, [SearchOptionsKey[fingerprint]]: value?.trim() }
      }

      if (key === paymentMethodBin) {
        return { ...acc, [SearchOptionsKey[paymentMethodBin]]: value?.trim() }
      }

      if (key === paymentSource) {
        return { ...acc, [SearchOptionsKey[paymentSource]]: value?.trim() }
      }

      if (key === isSubsequentPayment) {
        return {
          ...acc,
          [SearchOptionsKey[isSubsequentPayment]]: value?.trim(),
        }
      }

      if (key === merchantInitiated) {
        return { ...acc, [SearchOptionsKey[merchantInitiated]]: value?.trim() }
      }

      if (key === giftCardLast4) {
        return {
          ...acc,
          [SearchOptionsKey[giftCardLast4]]: value?.trim(),
        }
      }

      if ([label, last4].includes(key as SearchOptionsIndex)) {
        const labelIndex = SearchOptionsKey[label] as keyof typeof acc
        return {
          ...acc,
          [SearchOptionsKey[label]]: acc[labelIndex]
            ? [acc[labelIndex], value?.trim()]
            : value?.trim(),
        }
      }

      if (!value && EMAIL_REGEX.test(key)) {
        return { ...acc, [SearchOptionsKey[email]]: key?.trim() }
      }

      if (!value && UUID_REGEX.test(key)) {
        return { ...acc, [SearchOptionsKey[id]]: key?.trim() }
      }

      if (
        key &&
        value &&
        ![
          id,
          reconciliationId,
          connectorId,
          email,
          label,
          last4,
          merchantReference,
          fingerprint,
        ].includes(key as SearchOptionsIndex)
      ) {
        return {
          ...acc,
          ...(acc?.[SearchOptionsKey.VisitorID]
            ? {
                [SearchOptionsKey.VisitorID]: JSON.stringify({
                  ...JSON.parse(acc[SearchOptionsKey.VisitorID] as string),
                  [key]: value.trim(),
                }),
              }
            : {
                [SearchOptionsKey.VisitorID]: JSON.stringify({
                  [key]: value.trim(),
                }),
              }),
        }
      }
      return { ...acc, [key]: value?.trim() }
    }, {} as Partial<AdvancedSearchFilters>) as Partial<AdvancedSearchFilters>

  const cleanedUnusedFilters = Object.fromEntries(
    Object.keys(emptyAdvancedSearchFilters)
      .filter((emptyFilter) => !Object.keys(newFilters).includes(emptyFilter))
      .map((emptyFilter) => [emptyFilter])
  )

  return { ...newFilters, ...cleanedUnusedFilters }
}

export const getDefaultValue = (searchParams: URLSearchParams) => {
  const hasOnlyNumbersRegex = new RegExp(/^\d+$/)
  const beginOrFinishWithLetterRegex = new RegExp(/^[^\d].*[^\d]$/)

  return Array.from(searchParams)
    .reduce((acc: string, [key, value]) => {
      if (key === SearchOptionsKey.VisitorID) {
        const metadataParams = JSON.parse(searchParams.get(key) as string)
        let formattedInputValue = ''
        Object.entries(metadataParams).forEach((metadataEntry) => {
          const [paramKey, paramValue] = metadataEntry
          formattedInputValue =
            `${formattedInputValue} ${paramKey}=${paramValue}`.trim()
        })

        return `${acc} ${formattedInputValue}`
      }

      if (key === SearchOptionsKey.reconciliation_id) {
        return `${acc} ${reconciliationId}=${value}`
      }

      if (key === SearchOptionsKey.connector_id) {
        return `${acc} ${connectorId}=${value}`
      }

      if (key === SearchOptionsKey.merchant_reference) {
        return `${acc} ${merchantReference}=${value}`
      }

      if (key === SearchOptionsKey.label && value.split(',').length === 2) {
        const last4Search = value
          .split(',')
          .find((currSearch) => currSearch.match(hasOnlyNumbersRegex))
        const labelSearch = value
          .split(',')
          .find((currSearch) => currSearch.match(beginOrFinishWithLetterRegex))

        const sortedResult = value.split(',')[0].match(hasOnlyNumbersRegex)
          ? `${last4}=${last4Search} ${label}=${labelSearch}`
          : `${label}=${labelSearch} ${last4}=${last4Search}`

        return `${acc} ${sortedResult}`
      }

      if (key === SearchOptionsKey.last4 && value.match(hasOnlyNumbersRegex)) {
        return `${acc} ${last4}=${value}`
      }

      if (key === SearchOptionsKey.gift_card_last4) {
        return `${acc} ${giftCardLast4}=${value}`
      }

      if (
        key === SearchOptionsKey.label &&
        value.match(beginOrFinishWithLetterRegex)
      ) {
        return `${acc} ${label}=${value}`
      }

      if (key === SearchOptionsKey.email) {
        return `${acc} ${email}=${value}`
      }

      if (key === SearchOptionsKey.fingerprint) {
        return `${acc} ${fingerprint}=${value}`
      }

      if (key === SearchOptionsKey.bin) {
        return `${acc} ${paymentMethodBin}=${value}`
      }

      if (key === SearchOptionsKey.payment_source) {
        return `${acc} ${paymentSource}=${value}`
      }

      if (key === SearchOptionsKey.is_subsequent_payment) {
        return `${acc} ${isSubsequentPayment}=${value}`
      }

      if (key === SearchOptionsKey.merchant_initiated) {
        return `${acc} ${merchantInitiated}=${value}`
      }

      if (Object.values(SearchOptionsKey).includes(key as SearchOptionsKey)) {
        return `${acc} ${key}=${value}`
      }

      return acc
    }, '')
    .trim()
}

export const getKeyFromString = (string: string) => string.split('=')[0]
