import { User } from 'users/services/users'

export const getUserMerchantAccountIds = (user: User) =>
  user.merchantAccounts.map((merchantAccount) => merchantAccount.id)

export const getMerchantAccountOverCapture = (
  value: number,
  overCaptureAmount: number | null,
  overCapturePercentage: number | null
) => {
  if (!overCaptureAmount || !overCapturePercentage) {
    return 0
  }
  const maxAmount = overCaptureAmount + value
  const maxPercentage = value * (1 + overCapturePercentage / 100)
  return Math.min(maxAmount, maxPercentage)
}
