/* eslint-disable camelcase */
import { BadgeProps } from '@gr4vy/poutine-react'
import { TransactionStatus } from 'shared/services/transactions'
import { PayoutStatus } from 'transactions/services'

export type StatusConfig = {
  label: string
  color: BadgeProps['color']
}

type StatusItemProps = {
  status: TransactionStatus
  label: string
}

const transactionStatuses: Record<TransactionStatus, StatusConfig> = {
  buyer_approval_pending: {
    label: 'Authenticating',
    color: 'neutral',
  },
  authorization_succeeded: {
    label: 'Authorized',
    color: 'notice',
  },
  capture_succeeded: {
    label: 'Captured',
    color: 'positive',
  },
  settled: {
    label: 'Settled',
    color: 'positive',
  },
  capture_pending: {
    label: 'Capturing',
    color: 'neutral',
  },
  authorization_declined: {
    label: 'Declined',
    color: 'negative',
  },
  authorization_failed: {
    label: 'Failed',
    color: 'negative',
  },
  processing: {
    label: 'Processing',
    color: 'neutral',
  },
  authorization_voided: {
    label: 'Voided',
    color: 'positive',
  },
  authorization_void_pending: {
    label: 'Voiding',
    color: 'neutral',
  },
}

const payoutStatuses: Record<PayoutStatus, StatusConfig> = {
  declined: {
    label: 'Declined',
    color: 'negative',
  },
  failed: {
    label: 'Failed',
    color: 'negative',
  },
  pending: {
    label: 'Pending',
    color: 'neutral',
  },
  succeeded: {
    label: 'Succeeded',
    color: 'positive',
  },
}

const statuses: Record<
  string,
  typeof transactionStatuses | typeof payoutStatuses
> = {
  transaction: transactionStatuses,
  payout: payoutStatuses,
}

const getStatusConfig = (
  mapping: Record<any, StatusConfig | void>,
  status: any
): StatusConfig => {
  return {
    // Fallback values
    label: status,
    color: 'neutral',
    ...mapping[status],
  }
}

export const getTransactionStatusConfigs = (): Record<
  TransactionStatus,
  StatusConfig
> => transactionStatuses

export const getTransactionStatusConfig = (
  status: TransactionStatus | PayoutStatus,
  type = 'transaction'
): StatusConfig => {
  return getStatusConfig(statuses[type], status)
}

const QUICK_FILTER_EXCLUSIVE_STATUSES = ['settled']
export const transactionStatusFilterList: StatusItemProps[] = Object.entries(
  getTransactionStatusConfigs()
).reduce(
  (acc: StatusItemProps[], [key, { label }]) =>
    QUICK_FILTER_EXCLUSIVE_STATUSES.includes(key)
      ? acc
      : [...acc, { status: key as TransactionStatus, label }],
  []
)
