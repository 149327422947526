import { Flex, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { ReactNode } from 'react'
import { filter } from 'connections/components/Filters/select-filter'
import { PaymentServiceDefinitionField } from 'connections/services'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { timezones } from 'shared/constants/timezone'

export type SettlementReportingFieldProps = {
  item: PaymentServiceDefinitionField
  requiredMessage?: string
  disabled?: boolean
  group?: string
  onEdit?: (isEditing: boolean) => void
  extra?: ReactNode
}

export const SettlementReportingField = ({
  item,
  requiredMessage = 'Please provide a value for this field',
  disabled,
  group,
  onEdit,
  extra,
}: SettlementReportingFieldProps) => {
  return item.format === 'timezone' ? (
    <Stack gap={16}>
      <Stack gap={8}>
        <Heading level={5}>Time zone</Heading>
        <Text>Set the settlement timezone for the report to be generated.</Text>
      </Stack>
      <FormItem
        label={<Label>Time zone</Label>}
        name={[group, 'timezone']}
        rules={[
          {
            required: item.required,
            message: requiredMessage,
          },
        ]}
        initialValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
      >
        <Select
          listItemHeight={32}
          placeholder="Select an option"
          filterOption={filter}
          showSearch
          disabled={disabled}
        >
          {timezones.map(({ code, name, offsetFormatted }) => (
            <Select.Option key={code} value={code}>
              <Flex justifyContent="space-between" width="full">
                <Text as="span">{name}</Text>
                <Text
                  as="span"
                  style={{
                    fontVariantNumeric: 'tabular-nums',
                  }}
                >
                  UTC{offsetFormatted}
                </Text>
              </Flex>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    </Stack>
  ) : (
    <CredentialField
      key={item.key}
      item={{
        key: item.key,
        displayName: <Label>{item.displayName}</Label>,
        format: item.format,
      }}
      required={item.required}
      requiredMessage={requiredMessage}
      secret={item.secret}
      disabled={disabled}
      group={group}
      onEdit={onEdit}
      extra={extra}
    />
  )
}
