import { Description, Heading, Stack } from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import { Summary } from 'shared/components/Summary'
import currencyFormat from 'shared/helpers/currency-format'
import {
  TransactionStatusValues,
  ConnectorTransactionStatusValues,
} from 'shared/services/transactions'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { getTransactionStatusConfig } from 'transactions/constants'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const TransactionSyncEvent = ({
  event,
}: {
  event: TransactionEvent
}) => {
  const previousStatus = event.context.previousStatus
  const newStatus =
    // New status is either the literal new `status` or the sttaus mapped from
    // a connector status, and otherwise it's likely just the previous status
    event.context.status in TransactionStatusValues
      ? event.context.status
      : ConnectorTransactionStatusValues[event.context.status] || previousStatus
  // If new status was not an expected core or connector value, return the raw status
  const rawStatus =
    event.context.status in TransactionStatusValues ||
    ConnectorTransactionStatusValues[event.context.status]
      ? null
      : event.context.status

  return event ? (
    <Stack gap={32}>
      <Stack gap={8}>
        <Heading as="h5" level={5}>
          Connection
        </Heading>
        <EventsConnection event={event} />
      </Stack>
      <Summary className={styles.summary} inline>
        <Summary.Key>Previous status</Summary.Key>
        <Summary.Value fallback={!previousStatus}>
          <Description>
            <Description.Icon
              size={24}
              src={<Status value={previousStatus} />}
            />
            <Description.Text>
              {getTransactionStatusConfig(previousStatus).label}
            </Description.Text>
          </Description>
        </Summary.Value>
        {rawStatus && (
          <>
            <Summary.Key>Received status</Summary.Key>
            <Summary.Value fallback={!rawStatus}>
              <Description>
                <Description.Icon size={24} src={<Status value="voided" />} />
                <Description.Text>{rawStatus}</Description.Text>
              </Description>
            </Summary.Value>
          </>
        )}
        <Summary.Key>New status</Summary.Key>
        <Summary.Value fallback={!newStatus}>
          <Description>
            <Description.Icon size={24} src={<Status value={newStatus} />} />
            <Description.Text>
              {getTransactionStatusConfig(newStatus).label}
            </Description.Text>
          </Description>
        </Summary.Value>
        {!!event.context.amount && event.context.currency && (
          <>
            <Summary.Key>Authorized amount</Summary.Key>
            <Summary.Value fallback={!newStatus}>
              <Description>
                <Description.Text>
                  {currencyFormat(event.context.amount, {
                    currency: event.context.currency,
                  })}
                </Description.Text>
              </Description>
            </Summary.Value>
          </>
        )}
        {!!event.context.capturedAmount && event.context.currency && (
          <>
            <Summary.Key>Captured amount</Summary.Key>
            <Summary.Value fallback={!newStatus}>
              <Description>
                <Description.Text>
                  {currencyFormat(event.context.capturedAmount, {
                    currency: event.context.currency,
                  })}
                </Description.Text>
              </Description>
            </Summary.Value>
          </>
        )}
      </Summary>
    </Stack>
  ) : null
}
