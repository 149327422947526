import { Button, Grid, GridItem, Stack } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form } from 'antd'
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConnectionActions } from 'connections/components/ConnectionActions'
import {
  PaymentServiceUpdate,
  PaymentService,
  AntiFraudService,
  AntiFraudServiceUpdate,
  GiftCardServiceUpdate,
  GiftCardService,
} from 'connections/services'
import { FormPromptUnsavedChanges } from 'shared/components/FormPromptUnsavedChanges'
import { Loading } from 'shared/components/Loading'
import { PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/connections'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export interface EditConnectionFormData
  extends Omit<
    PaymentService | AntiFraudService | GiftCardService,
    'fields' | 'createdAt' | 'updatedAt'
  > {
  fields: Record<string, string>
  reporting_fields?: Record<string, string>
}

interface EditConnectionFormProps<T, TUpdate> {
  children: ReactNode
  submitButtonText?: string
  subNavigation: Array<{ title: string; url: string }>
  data: T | undefined
  isLoading: boolean
  remove: UseMutationResult<void, any, string, unknown>
  update: UseMutationResult<T, any, TUpdate, unknown>
  mapFormToService: (form: EditConnectionFormData) => TUpdate
  hasFooterButtons?: boolean
}

export const EditConnectionForm = <
  T extends PaymentService | AntiFraudService | GiftCardService,
  TUpdate extends
    | PaymentServiceUpdate
    | AntiFraudServiceUpdate
    | GiftCardServiceUpdate,
>({
  children,
  subNavigation,
  submitButtonText,
  data,
  isLoading,
  remove,
  update,
  mapFormToService,
  hasFooterButtons = true,
}: EditConnectionFormProps<T, TUpdate>) => {
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const onDelete = () => {
    setInitialValues(form.getFieldsValue())
    remove.mutate(id, {
      onSuccess: () => {
        navigate(pathTo.connectionsConfigured(merchantAccountId), {
          replace: true,
        })
      },
    })
  }
  const onCancel = (e: SyntheticEvent) => {
    e.preventDefault()
    navigate(pathTo.connectionsConfigured(merchantAccountId), { replace: true })
  }

  const mapServiceToFormValues = (
    service?: PaymentService | AntiFraudService | GiftCardService
  ) =>
    ({
      ...service,
      fields: service?.fields?.reduce(
        (previousValue, currentValue) => {
          const { key, value } = currentValue
          previousValue[key] = value
          return previousValue
        },
        {} as Record<string, string>
      ),
    }) as EditConnectionFormData

  const [initialValues, setInitialValues] = useState<EditConnectionFormData>(
    undefined as unknown as EditConnectionFormData
  )

  useEffect(() => {
    if (data) {
      form.resetFields()
      form.setFieldsValue(mapServiceToFormValues(data))
      setInitialValues(mapServiceToFormValues(data))
    }
  }, [data, form])

  if (isLoading || !data || !initialValues) {
    return <Loading />
  }

  const onFinish = (values: EditConnectionFormData) => {
    const payload = mapFormToService(values)
    setInitialValues(values)
    update.mutate(payload)
  }

  const breadcrumbs = [
    {
      title: 'Configured',
      url: pathTo.connectionsConfigured(merchantAccountId),
    },
    { title: `${hasWritePermission ? 'Edit' : 'View'} connection` },
  ]

  const renderFooterButtons = () => {
    if (!hasFooterButtons) {
      return null
    }

    return hasWritePermission ? (
      <Stack direction="row" gap={16} marginTop={16}>
        <Button
          loading={update.isPending}
          loadingText="Saving connection"
          variant="primary"
          disabled={!hasWritePermission}
        >
          {submitButtonText}
        </Button>
      </Stack>
    ) : (
      <Stack direction="row" gap={16} marginTop={16}>
        <Button variant="secondary" onClick={onCancel}>
          Back to connections
        </Button>
      </Stack>
    )
  }

  return (
    <PageLayout
      title={data.displayName ?? ''}
      breadcrumbs={breadcrumbs}
      subNavigation={subNavigation}
      actions={
        <ConnectionActions
          onDelete={onDelete}
          isReadOnlyPermission={!hasWritePermission}
        />
      }
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Form
            form={form}
            initialValues={initialValues}
            autoComplete="off"
            layout={'vertical'}
            requiredMark={false}
            scrollToFirstError={{ block: 'center' }}
            onFinish={onFinish}
          >
            <Stack gap={24}>
              {children}
              {renderFooterButtons()}
            </Stack>
          </Form>
        </GridItem>
      </Grid>
      <FormPromptUnsavedChanges form={form} initialValues={initialValues} />
    </PageLayout>
  )
}
