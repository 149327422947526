import { useParams } from 'react-router-dom'
import { LayoutProps, PageLayout } from 'shared/components/PageLayout'
import { pathTo } from 'shared/paths/connections'

export const ApplePayLayout = ({ children, ...rest }: LayoutProps) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }

  const subNavigation = [
    {
      title: 'Overview',
      url: pathTo.editConnectionApplePayOverview(merchantAccountId, id),
    },
    {
      title: 'Web Domains',
      url: pathTo.editConnectionApplePayDomains(merchantAccountId, id),
    },
    {
      title: 'iOS Certificates',
      url: pathTo.editConnectionApplePayCertificates(merchantAccountId, id),
    },
  ]

  const breadcrumbs = [
    {
      title: 'Configured',
      url: pathTo.connectionsConfigured(merchantAccountId),
    },
    {
      title: 'View connection',
    },
  ]

  return (
    <PageLayout
      subNavigation={subNavigation}
      breadcrumbs={breadcrumbs}
      {...rest}
    >
      {children}
    </PageLayout>
  )
}
